.side_cell_body {
  display: flex;
  // flex-direction: row;
  // justify-content: center;
  align-items: center;
  height: 57px;
  padding: 14.5px 20px;
  background: #17172e;
  color: #a8a8cd;
  transition: 0.3s;

  &:not(.active):hover {
    background: #2a2a4a;
    color: #a8a8cd;
    cursor: pointer;
  }

  &.active {
    background: #f7f7f7;
    color: #17172e;

    & .arrow {
      transform: rotate(180deg);
    }
    svg {
      path {
        fill: #17172e;
      }
    }
  }

  .label {
    margin-left: 23px;
  }

  .arrow {
    margin-left: auto;
    cursor: pointer;
  }
}

.iconContainer {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
