.react_time_range__rail__outer,
.react_time_range__rail__inner,
.react_time_range__track,
.react_time_range__track__disabled {
  height: 150px !important;
}

.react_time_range__tick_label {
  margin-top: 85px !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #7d7d8a !important;
}
.react_time_range__track__disabled {
  z-index: 1 !important;
  border-left: 1px solid #c0c0e2 !important;
  border-right: 1px solid #c0c0e2 !important;
  background: repeating-linear-gradient(-45deg,
      #ffffff,
      #ffffff 20px,
      #c0c0e2 22px) !important;
}

.react_time_range__handle_wrapper {
  height: 80px !important;
  width: 30px !important;
  border-radius: 100px !important;
}

.react_time_range__handle_marker,
.react_time_range__handle_marker__error {
  transition: all !important;
  width: 0px !important;
  height: 36px !important;
  border: 4px solid #91cb3f !important;
  border-radius: 100px !important;
}

.react_time_range__track {
  transition: all 0.2s !important;
  background-color: rgba(145, 203, 63, 0.4) !important;
  border-left: rgba(145, 203, 63, 0.4) !important;
  border-right: rgba(145, 203, 63, 0.4) !important;
}

.time_container {
  transition: all 0.2s;

  &.error {
    border: 2px solid #ec5d5d;

    .react_time_range__track {
      background-color: #ec5d5d85 !important;
      border-left: 1px solid #ec5d5d85 !important;
      border-right: 1px solid #ec5d5d85 !important;
    }

    .react_time_range__handle_marker__error {
      border: 4px solid #ec5d5d;
    }
  }
}

.react_time_range__rail__inner {
  background-color: #fff !important;
  border-bottom: unset !important;
}

.react_time_range__tick_marker {
  height: 20px !important;
  margin-top: 56px !important;
}

.react_time_range__tick_marker__large {
  height: 33px !important;
  margin-top: 43px !important;
}

.react_time_range__handle_container,
.react_time_range__handle_container__disabled {
  transition: all 0.2s;
  height: 80px !important;
  width: 30px !important;
  background: #ffffff;
  border: 1px solid #e8e8ef;
  border-radius: 100px !important;
}