.search_input {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 18.5px 20px;

    background: #FFFFFF;
    /* Stroke gray */

    border: 1px solid #E8E8EF;
    box-sizing: border-box;
    border-radius: 8px;

    & .search {
        margin-right: 16px;
        display: flex;
        align-items: center;
    }

    & input {
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        /* identical to box height */

        letter-spacing: 0.16px;

        /* Placeholder gray */

        color: #7D7D8A;
        width: 100%;

        border-width: 0px;
        border: none;

        padding: 0px;

        &:focus {
            outline: none!important;
        }
    }
}