.form {
  display: grid;
  gap: 24px;
}

.InputBox {
  transition: all .2s;
  max-width: 916px;
  width: 100%;
  position: relative;
  z-index: 2;

  & .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    color: #17172e;
    font-family: 'DM Sans', sans-serif;
    padding: 0;
    margin-bottom: 6px;
  }

  &.disable {
    position: relative;
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f7f7f7ad;
      pointer-events: none;
    }
  }
}

.ButtonBox {
  padding-top: 36px;
}

.groupButton {
  display: flex;
  align-items: center;
  max-width: 916px;

  & Button:nth-child(1) {
    margin-right: auto;
  }

  & Button:nth-child(2) {
    margin-right: 20px;
  }
}

.lineParams {
  position: relative;
  z-index: 0;

  &_role {
    z-index: 1;
  }

  &_door {}

  &_date {
    &.active {
      z-index: 1;
    }
  }

  display: flex;

  & .InputBox {
    max-width: 450px;
    width: 100%;

    & .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.3;
      display: flex;
      align-items: center;
      color: #17172e;
      font-family: 'DM Sans', sans-serif;
      padding: 0;
      margin-bottom: 6px;
    }

    &:first-child {
      margin-right: 16px;
    }
  }
}

.input {
  &_title {
    color: #17172e;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &_multi {
    margin-bottom: 30px;
  }
}

.description {
  margin-bottom: 60px;

  &_title {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;
    line-height: 34px;
    color: #000000;
  }

  &_subtitle {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}

.details_svg {
  margin-right: 10px;
}

.door {
  &_add {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0px;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 60px;
    height: 59px;
    background: #9e00ff;
    border-radius: 10px;
    color: #fff;
    font-weight: normal;
    font-size: 18px;
    line-height: 12px;

    &:hover {
      background: #8506d4;
    }
  }
}

.door_container {
  position: relative;
  overflow-y: auto;
}

.form_btn__wrap {
  display: flex;
  align-items: center;

  button {
    &:first-child {
      margin-right: 20px;
    }
  }
}