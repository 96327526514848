.tab {
  &_link {
    transition: all 0.2s;
    font-weight: normal;
    font-size: 16px;
    color: #7d7d8a;
    min-width: 220px;
    background: #f1f1f5;
    border-radius: 30px;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #17172ea8;
      color: #fff;
    }

    &.active {
      background: #17172e;
      color: #fff;
    }
    
  }
  &_navigation {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 6px;
    background: #f1f1f5;
    border-radius: 30px;
    margin: 0px 40px;
    margin-bottom: 50px;
  }
}
