.wrap_modal {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  height: 100%;
  background: rgba(241, 241, 245, 0.58);
  backdrop-filter: blur(3px);
}
.modal_content {
  //   position: relative;
  position: sticky;
  top: 50%;
  left: calc(50% - 150px);
  transform: translate(calc(50% - 350px), -50%);
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(168, 168, 205, 0.3);
  border-radius: 30px;
  padding: 50px 85px 75px;
  color: #fff;
  max-width: 684px;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 26px;
    right: 26px;
    width: 100%;
    svg {
      width: 15px;
      height: 15px;
      cursor: pointer;
      line {
        stroke: #fff;
      }
    }
  }
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .modal_content {
    margin: 0 40px;
  }
}
@media (max-width: 350px) {
  .modal_content {
    width: 95%;
    padding: 5px 25px;
    & .main {
      margin-top: 10px;
    }
  }
}
