.rootWrap {
  display: flex;
  height: 100%;

  & .mainContent {
    display: flex;
    flex-direction: column;
    width: calc(100% - 350px);

    & .content {
      h1 {
        margin-left: 40px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .rootWrap .mainContent {
    width: calc(100% - 270px);
  }
}
