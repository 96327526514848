.ant-checkbox-wrapper {
    align-items: center;
}

.ant-checkbox {
    width: 18px;
    height: 18px;
}

.ant-checkbox-checked::after {
    border: none;
}

.ant-checkbox-inner {
    border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #9E00FF;
    border-color: #9E00FF;
}