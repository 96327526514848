.form {
  display: grid;
  gap: 24px;
}

.InputBox {
  max-width: 916px;
  width: 100%;
  position: relative;
}
.notEdit {
  cursor: not-allowed;
}
.title {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #17172e;
  font-family: "DM Sans", sans-serif;
  padding: 0;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.line_title {
  position: absolute;
  top: -38px;
  left: 0;
}
.subtitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.16px;
  color: #9a9aae;
}
.groupButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 916px;
}

.lineParams {
  margin-top: 30px;
  position: relative;
  display: flex;
  align-items: center;
  & .InputBox {
    max-width: 450px;
    width: 100%;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.3;
      display: flex;
      align-items: center;
      color: #17172e;
      font-family: "DM Sans", sans-serif;
      padding: 0;
      margin-bottom: 6px;
    }

    &:last-child {
      margin-left: 16px;
    }
  }
}
.site__wrapper {
  transition: all 0.2s;

  &.active {
    opacity: 0.5;
    pointer-events: none;
  }
}
#input_photo {
  position: absolute;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.input {
  &_title {
    color: #17172e;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.inputNotEdit {
  height: 58px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;

  border: 2px solid #e8e8ef;
  width: 100%;
  position: relative;
  & .value {
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.16px;
    color: #17172e;
    width: 100%;
    padding: 17px;
    cursor: default;
  }
  & .iconEdit {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0%, -50%);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.button {
  margin-top: 40px;
}

.data__wrapped {
  position: relative;
  z-index: 99;
}
