.blurBackground {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(241, 241, 245, 0.58);
    backdrop-filter: blur(3px);

    & .modalBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    
        & svg {
            display: inline-block;
            position: absolute;
            width: 50px;
            height: 50px;
            animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

            & #long {
                transition-delay: -0.036s;
            }
            & #dot1 {
                transition-delay: -0.072s;
            }
            & #dot2 {
                transition-delay: -0.108s;
            }
        }
    
        @keyframes loader {
            0% {
                transform: rotate(0deg);
            }
    
            100% {
                transform: rotate(360deg);
            }
        }
    
    }
}