.container {
  padding: 0px 40px;
}
.title {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #17172e;
}
.points {
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 0.16px;
  color: #17172e;
  margin-bottom: 20px;

  margin-top: 15px;

  &_title {
    padding-left: 10px;
  }
}

.error {
  margin-top: 10px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  width: fit-content;
  &_svg {
    display: flex;
    padding: 14px;
    background: #ec5d5d;
    width: 60px;
    height: 100%;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  &_text {
    background: #ffffff;
    padding: 10px 20px;
    width: calc(100% - 60px);
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.16px;
    max-width: 400px;
    color: #17172e;
  }
}
.form {
  position: relative;
  transition: all 0.2s;

  &.deactivate {
    opacity: 0.2;
    * {
      pointer-events: none;
    }
  }
}
