.form {
	display: grid;
	gap: 24px;
}

.InputBox {
	max-width: 916px;
	width: 100%;
	position: relative;

	& .title {
		font-weight: 500;
		font-size: 16px;
		line-height: 1.3;
		display: flex;
		align-items: center;
		color: #17172e;
		font-family: "DM Sans", sans-serif;
		padding: 0;
		margin-bottom: 6px;
	}

	& .inputNotEdit .value {
		font-size: 16px;
		line-height: 1.9;
		letter-spacing: 0.16px;
		color: #17172e;
		max-width: 95%;
		padding: 17px;
	}
}

.user_activated_checkbox {
	position: relative;
	height: 0;
	width: 0;
	opacity: 0;
	pointer-events: none;
}

.ButtonBox {
	padding-top: 36px;
}

.groupButton {
	display: flex;
	align-items: center;
	max-width: 916px;

	& Button:nth-child(1) {
		margin-right: auto;
	}

	& Button:nth-child(2) {
		margin-right: 20px;
	}
}

.lineParams {
	display: flex;
	align-items: center;

	& .InputBox {
		max-width: 450px;
		width: 100%;

		& .title {
			font-weight: 500;
			font-size: 16px;
			line-height: 1.3;
			display: flex;
			align-items: center;
			color: #17172e;
			font-family: "DM Sans", sans-serif;
			padding: 0;
			margin-bottom: 6px;
		}

		&:last-child {
			margin-left: 16px;
		}
	}
}

.column3 {
	display: flex;
	align-items: center;

	& .InputBox {
		max-width: 295px;
		width: 100%;
		margin-right: 16px;

		& .title {
			font-weight: 500;
			font-size: 16px;
			line-height: 1.3;
			display: flex;
			align-items: center;
			color: #17172e;
			font-family: "DM Sans", sans-serif;
			padding: 0;
			margin-bottom: 6px;
		}
	}
}

.soloLine {
	.InputBox {
		margin-left: 0px !important;
	}
}

.bottomFormContainer {
	position: relative;

	.InputBox {
		position: relative;
		top: -20px;

		&:nth-child(2) {
			top: -35px;

			* {
				font-weight: 700;
			}
		}
	}
}