.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    max-width: 250px;
    width: 100%;
    height: 60px;
    border-radius: 10px;

    background: #9e00ff;
    color: #ffffff;

    &.wide {
        max-width: 309px;
    }

    & path {
        fill: #ffffff;
    }

    transition: 0.3s;

    &:hover {
        cursor: pointer;
    }

    &.main {
        background: #9e00ff;
        color: #ffffff;

        & path {
            fill: #ffffff;
        }

        &:hover {
            background: #ba48ff;
        }
    }

    &.secondary {
        background: #ffffff;
        /* Accent */

        border: 1px solid #9e00ff;
        box-sizing: border-box;
        color: #9e00ff;
        & path {
            fill: #9E00FF;
        }
        &:hover {
            color: #ba48ff;
            border: 1px solid #ba48ff;
        }
    }

    &.disabled {
        background: #e8e8ef;
        pointer-events: none;
        /* Accent */

        color: #ffffff;
        & path {
            fill: #ffffff;
        }
    }

    &.red {
        background: #ec5d5d;
        /* Accent */
        color: #ffffff;
        & path {
            fill: #ffffff;
        }
        &:hover {
            background: #ff7e7e;
        }
    }

    &.green {
        background: #73b812;
        /* Accent */
        color: #ffffff;
        & path {
            fill: #ffffff;
        }
        &:hover {
            background: #9bcb58;
        }
    }
    &.gray {
        background: #ffffff;
        /* Accent */

        border: 1px solid #a8a8cd;
        box-sizing: border-box;

        color: #a8a8cd;
        & path {
            fill: #a8a8cd;
        }
    }

    & .icon {
        display: flex;
        align-items: center;
    }

    & .left_icon {
        margin-right: 10px;
    }

    & .right_icon {
        margin-left: 10px;
    }
}
