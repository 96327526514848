.book {
  transition: all 0.2s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 115px;
  margin-left: 30px;
  display: flex;
  height: 90%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.16px;
  color: #9e00ff;
  background: #ffffff;
  border: 1px solid #9e00ff;
  box-sizing: border-box;
  border-radius: 10px;

  &:hover {
    color: #ffffff;
    background: #9e00ff;
    border: 1px solid #ffffff;
  }
}
