.title {
  padding: 40px;
  font-weight: bold;
  color: #17172e;
  font-size: 26px;
  line-height: 34px;
  display: flex;
  align-items: center;
}

.members {
  &_info {
    margin-left: 30px;
    width: 190px;
    height: 47px;
  }
  &_btn {
    transition: all .2s;
    border: 1px dashed #7a7a96;
    border-radius: 20px;
    padding: 5px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    cursor: pointer;
    svg {
      path, circle {
        transition: all .2s;
      }
    }



    span {
      transition: all .2s;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.015em;
      color: #7a7a96;
    }

    &:hover {
    border: 1px dashed #9e00ff;
      span {
        color: #9e00ff;
      }
      svg {
        path {
          stroke: #9e00ff;
        }
        circle {
          fill: #9e00ff;
        }
      }
    }
  }
  &_count {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.015em;
    color: #7a7a96;
    border: 1px dashed #7a7a96;
    border-radius: 20px;
    padding: 5px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
}

.table {
  &_head_item {
  }

  &_row_item {
    position: relative;
    transition: all 0.2s;
    background: #f1f1f5;

    &:nth-child(2n) {
      background: #f7f7f7;
    }

    &:hover {
      background: #e1e1e6;
    }

    &.default {
      cursor: default !important;
    }
  }

  &_container {
    width: 100%;
  }

  &__wrapper {
    scroll-behavior: smooth;
    position: relative;
    z-index: 1;
    box-shadow: unset !important;
    height: calc(100vh - 100px - 114px);
    width: 100%;
    overflow: auto;

    table,
    td,
    th {
      border: transparent;
      overflow-x: hidden !important;
    }

    table {
      background: #f7f7f7;
      margin-bottom: 0px !important;
      // margin-bottom: 70px !important;
    }

    thead {
      th {
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        padding-top: 10px;
        padding-bottom: 10px;

        span,
        svg {
          color: #a8a8cd !important;
        }

        &:first-child {
          padding-left: 40px;
        }

        &:last-child {
          padding-right: 40px;
        }
      }
    }

    tbody {
      .table {
        &_cell {
          color: #17172e;
          padding: 27px 0;

          &:first-child {
            padding-left: 40px;
          }

          &:last-child {
            padding-right: 40px;
          }
        }
      }

      td {
        padding-right: 0;

        &:first-child {
          padding-left: 40px;
        }

        &:last-child {
          padding-right: 40px;
        }
      }

      tr {
      }
    }
  }
}

.pagination {
  margin: 0;
  display: flex;
  align-items: center;

  &_page_number {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.66;
    padding-right: 40px;
    letter-spacing: 0.03333em;
  }

  &_prev {
  }

  &__wrapper {
    width: auto;
    display: flex;
    align-items: center;
    overflow-x: auto;
    max-width: 400px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_item {
    display: flex;
    transition: all 0.2s;
    padding: 0px 5px !important;
    width: auto !important;
    color: rgba(0, 0, 0, 0.47);
    border-radius: unset !important;

    &:hover {
    }

    &.active {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__block {
    flex: none;
    display: flex;
    padding: 12px;
    overflow: hidden;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    z-index: 9;
    bottom: 0;
    right: 0;
    background: #f7f7f7;
    width: calc(100% - 350px);

    button {
      font-size: 16px;
      transition: all 0.2s;
      cursor: pointer;
      width: 30px;
      height: 30px;
      background: transparent;
      margin: 0;
      padding: 0;
      outline: none;
      border: unset;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        width: 18px;
        fill: rgba(0, 0, 0, 0.54);
      }

      &:hover {
        background: rgba(0, 0, 0, 0.06);
      }

      &:disabled {
        opacity: 0.3;

        &:hover {
          background: transparent;
        }
      }
    }
  }
}

.hidden {
  opacity: 0.4;
  pointer-events: none;
}

@media (max-width: 1400px) {
  .pagination {
    &__block {
      width: calc(100% - 270px);
    }
  }
}
