@import url("fonts/style.css");

html {
  font-size: 16px;
  min-width: 280px;
  position: relative;
  line-height: 1.6;
  opacity: 1;
  margin: 0;
  z-index: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  -moz-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
}

body {
  overflow-x: hidden;
  margin: 0;
}

a {
  font-size: 16px;
  line-height: 1.2;
  text-align: right;
  text-decoration-line: underline;
  color: #000;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "DM Sans";

  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    background: #e1e1e1;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e1e1e1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000;
  }
}

table,
td,
th {
  border: 1px solid black;
}

.react-datepicker-ignore-onclickoutside {
  & input {
    width: 100%;
    height: 60px !important;
    border: 1px solid #e8e8ef;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 17px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    background: #fff;
  }
}


.TableContainer-root-2,
.jss2,
.jss49 {
  // padding-bottom: 72px;
  height: auto !important;
  overflow: initial !important;
}

.Pager-pager-33,
.jss33,
.jss86,
.jss82 {
  position: fixed;
  z-index: 9;
  bottom: 0;
  right: 0;
  background: #f7f7f7;
  width: calc(100% - 350px);
}

@media (max-width: 1400px) {

  .Pager-pager-33,
  .jss33,
  .jss86,
  .jss82 {
    width: calc(100% - 270px);
  }
}