.modalBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & .title {
    font-weight: bold;
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 0.16px;
    color: #17172e;
    margin-bottom: 15px;
  }
  & .description {
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.16px;
    color: #17172e;
    margin-bottom: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  & .control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.info {
  width: 100%;
  margin: 30px 0;

  &_item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  &_name {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #17172e;
    padding-right: 40px;
    width: 50%;
  }
  &_value {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #17172e;
    width: 50%;
  }
}
