.side_panel_header {
    display: flex;
    // flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    width: 100%;
    color: #7A7A96;
    
    & .first_half {
        display: flex;
        flex-direction: column;

        & .bottom_text {
            font-family: 'DM Sans';
        }
    }

    & .logoutButton {
        &:hover {
            cursor: pointer;
        }
    }
}
