.modalBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    & .title {
        font-weight: bold;
        font-size: 26px;
        line-height: 1.3;
        letter-spacing: 0.16px;
        color: #17172e;
        margin-bottom: 15px;
        text-align: center;
    }
    & .description {
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: 0.16px;
        color: #17172e;
        margin-bottom: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
    }
    & .control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.control {
  justify-content: center !important;
}