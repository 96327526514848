.delete {
  transition: all 0.2s;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7a7a96;
  cursor: pointer;

  svg {
    margin-right: 10px;
    margin-bottom: 2px;
    path {
      transition: all 0.2s;
    }
  }

  &:hover {
    color: #ec5d5d;

    svg {
      path {
        fill: #ec5d5d;
      }
    }
  }
}

.table_help {
  tbody td:first-child {
    padding-left: 40px;
    padding-right: 170px;
  }
}
