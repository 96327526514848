.form {
    display: grid;
    gap: 24px;
}

.InputBox {
    max-width: 916px;
    width: 100%;
    position: relative;
    & .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        display: flex;
        align-items: center;
        color: #17172e;
        font-family: "DM Sans", sans-serif;
        padding: 0;
        margin-bottom: 6px;
    }
}
.groupButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 916px;
}

.lineParams {
    display: flex;
    align-items: center;
    & .InputBox {
        max-width: 450px;
        width: 100%;
        & .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.3;
            display: flex;
            align-items: center;
            color: #17172e;
            font-family: "DM Sans", sans-serif;
            padding: 0;
            margin-bottom: 6px;
        }

        &:last-child {
            margin-left: 16px;
        }
    }
}

.inputNotEdit {
    height: 58px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;

    border: 2px solid #e8e8ef;
    width: 100%;
    position: relative;
    & .value {
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: 0.16px;
        color: #17172e;
        width: 100%;
        padding: 17px;
        cursor: default;
    }
    & .iconEdit {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0%, -50%);
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
