.side_panel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 100vh;
  background: #17172e;

  & .side_panel_item {
    margin-top: 40px;
  }
}
.container {
  position: sticky;
  top: 0;
}

@media (max-width: 1400px) {
  .side_panel {
    width: 270px;
  }
}
