.react-datepicker {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e8e8ef;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(168 168 205 / 30%);
  border-radius: 5px;
  background-color: #fff;
  min-width: 320px;
  width: 100%;
  &__triangle {
    display: none;
  }
  &__header {
    background-color: #fff;
    border: none;

    &--time {
      padding: 0;
    }
  }
  &__day-names {
    margin-top: 24px;
  }
  &__day-name {
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
    color: #17172e;
  }
  &__month {
    margin: 0;
    margin-top: 10px;
    &-container {
      width: 100%;
    }
  }
  &__week,
  &__day-names {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  &__day {
    outline: 0.5px solid #f1f1f5;
    border-radius: 0 !important;
    display: inline-block;
    width: auto;
    margin: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #17172e;
  }
  &__day--outside-month {
    background: #fff;
    color: #a8a8cd;
    &:hover {
      background-color: #fff;
    }
  }

  &__day--today {
    background: #f7f7f7;
    color: #7a7a96;
  }
  &__day--disabled {
    color: #f1f1f5;
  }
  &__day--keyboard-selected {
    background: #9e00ff;
    color: #fff;

    &:hover {
      background-color: #f0f0f0;
      color: #17172e;
    }
  }
  &__day--selected,
  &__day--in-range,
  &__day--in-selecting-range {
    background: #9e00ff !important;
    color: #fff !important;
    &:hover {
      background: #9e00ff !important;
      color: #fff !important;
      opacity: 0.7;
    }
  }
  &-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container {
    input {
      border: 2px solid #e8e8ef;
      padding: 17px !important;
      background: #fff;
      outline: none;
      border-radius: 8px;
      width: 100%;
    }
  }
  &-wrapper input {
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.16px;
    height: 60px;
    color: #17172e;
  }

  &__time {
    &-container {
      width: 100%;
    }
    &-box {
      width: 100% !important;
      text-align: left !important;
    }
    &-list-item {
      transition: all 0.2s;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      background-color: transparent;
      color: #17172e;
      &:hover {
        background-color: transparent !important;
        color: #9e00ff;
      }
      &--selected {
        background-color: #9e00ff !important;
        color: #fff;
        &:hover {
            background-color: #9e00ff !important;
            color: #fff;
          }
      }
    }
  }
}

.errorWrap {
  .react-datepicker__input-container {
    input {
      background: #fff7f7 !important;
      border: 2px solid #ec5d5d !important;
    }
  }
}
