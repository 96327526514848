.CustomHeaderDataPicer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .data {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #17172e;
        font-family: "DM Sans", sans-serif;
    }
    & .control {
        display: flex;
        align-items: center;
        & .prevMonth {
            transform: rotate(180deg);
        }
        & .prevMonth,
        & .nextMonth {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        & .nextMonth {
            margin-left: 15px;
        }
    }
}
.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ec5d5d;
    position: absolute;
    bottom: -22px;
}
.wrap {
    position: relative;
}
