.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  & .sign_in_window {

    & .header {


      & .welcome_message {
        color: #9A9AAE;
      }

      margin-bottom: 50px;
    }

    & .form {

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & .inputs_group {
        display: grid;
        grid-gap: 40px;
        width: 360px;
        margin-bottom: 30px;

        & .password_field {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 57px;

          & a {
            align-self: flex-end;
            text-decoration: underline;
            font-size: 14px;
            line-height: 24px;
            color: #7A7A96;
          }
        }
      }

      & .login_button {
        align-self: start;
        width: 250px;
        height: 60px;
      }
    }

  }
}

.title {
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 0.16px;
  color: #17172E;
  margin-bottom: 30px;
}
.subtitle {
  max-width: 250px;
  margin-bottom: 30px;
}