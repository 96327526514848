.title {
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 0.16px;
  color: #17172e;
  padding: 40px;
}
.boxForm {
  padding: 23px 40px;
}
.form {
  display: grid;
  gap: 24px;
}
.wrapper {
  max-width: 520px;
}
.area_pin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 210px;
  border: 2px dashed #7d7d8a;

}

.area_text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.16px;
    color: #7d7d8a;
    max-width: 270px;
}