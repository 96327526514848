.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  & .sign_in_window {

    & .header {


      & .welcome_message {
        color: #9A9AAE;
      }

      margin-bottom: 55px;
    }

    & .form {

      display: flex;
      flex-direction: column;

      align-items: center;

      & .inputs_group {
        display: grid;
        grid-gap: 40px;

        & .password_field {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 57px;

          & a {
            align-self: flex-end;
            text-decoration: underline;
            font-size: 14px;
            line-height: 24px;
            color: #7A7A96;
          }
        }
      }

      & .login_button {
        align-self: start;
        width: 250px;
        height: 60px;
      }
    }

  }
}