body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TableContainer-root-2 {
  padding-right: 10px;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
  width: 5px;
  /* ширина для вертикального скролла */
  height: 10px;
  /* высота для горизонтального скролла */
  background-color: #1e1e47;
}


/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  transition: all .2s;
  background-color: #FFFFFF;
  border: 0px solid #A8A8CD;
  border-radius: 31px;
  box-shadow: inset 1px 1px 10px #dbd7d7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c2c2c2;
}

* ::-webkit-scrollbar-track {
  background: #A8A8CD;
}