.pageContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #F7F7F7;
    position: relative;
    & .content {
        h1 {
            margin-left: 40px;
        }
    }
}