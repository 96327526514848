.container {
  padding: 0px 40px;
}

.boxForm {
  position: relative;
  z-index: 9;
  margin-bottom: 80px;
  padding-top: 40px;
}

.delete {
  position: absolute;
  z-index: 1;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
  font-weight: normal;
  font-size: 18px;
  line-height: 12px;
  transform: translateY(-50%);
  border: 2px solid #ec5d5d;
  right: -66px;
  top: 50%;
  width: 60px;
  height: 59px;

  svg {
    path {
      transition: all 0.2s;
      fill: #ec5d5d;
    }
  }

  &:hover {
    background: #ec5d5d;

    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.disable {
  position: relative;
}

.user {
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e8e8ef;
  border-radius: 8px;
  padding: 20px;
  max-width: calc(100% - 80px);
  margin-bottom: 20px;

  &__block {
    margin-bottom: 60px;

    &.disable {
      display: none;
    }
  }

  &_container {
    overflow-y: auto;
    margin-right: -20px;
    max-height: 400px;
  }

  &_title {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
    color: #17172e;
  }

  &_info {
    margin-right: 30px;

    &_name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #7d7d8a;
    }

    &_value {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: 0.16px;
      color: #17172e;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}