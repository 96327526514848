.wrap {
    width: 100%;
    position: relative;
    & .title {
        color: #17172e;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    & .hint {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        display: flex;
        align-items: center;

        /* Placeholder gray */

        color: #7d7d8a;

        &.error {
            color: #ec5d5d;
            max-width: 375px;
        }
    }

    & .input {
        width: 100%;

        height: 60px;

        border: 1px solid #e8e8ef;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 17px;

        display: flex;
        align-items: center;
        transition: 0.3s;
        background: #fff;
        &:focus-within {
            border: 1px solid #9e00ff;
        }

        & input, textarea {
            width: 100%;
            height: 100%;
            border-style: none;
            border-width: 0px;
            padding: 0;
            border: none;
            -webkit-appearance: none;
            font-size: 16px;
            line-height: 1.9rem;
            /* identical to box height */
            resize: unset;
            letter-spacing: 0.16px;

            /* Placeholder gray */

            &::placeholder {
                color: #7d7d8a;
            }

            &:disabled {
                color: #e8e8ef;
                background: #fff;
            }

            &:focus {
                outline: none !important;
            }
        }

        & .left_icon {
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }

        & .right_icon {
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }

        &.error {
            background: #fff7f7;
            border: 2px solid #ec5d5d;

            & input, textarea {
                background: #fff7f7;
            }
        }
    }
    & .textarea {
        height: auto;
    }
}
