.header {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 100px;
  padding: 20px;
  width: 100%;

  & .header_item {
    margin-right: 12px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0px;
    }

    &.site_selector_dropdown {
      display: flex;
      max-width: 250px;
      width: 100%;
      align-items: center;
      // display: flex;
      // align-items: center;
    }

    & .search_input {
      margin-right: 12px;
      width: 100%;
    }

    &.search_button {
      max-width: 65%;
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }
}

.text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.16px;
  color: #17172e;
  display: flex;
  align-items: center;
}
