.error {
  position: absolute;
  bottom: -22px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ec5d5d;
}
.wrap {
  position: relative;

  & .title {
    color: #17172e;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}

.disabled {
  & div {
    color: #c4c4c4;
    // background: #fff;
    pointer-events: none;
    box-sizing: border-box;
    border-radius: 8px;
  }
}
