.form {
  display: grid;
  gap: 24px;
}

.title {
  margin: 40px 0 -10px 0;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  color: #17172e;
}

.InputBox {
  
  width: 100%;
  position: relative;
  & .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    color: #17172e;
    font-family: 'DM Sans', sans-serif;
    padding: 0;
    margin-bottom: 6px;
  }
}

.time__wrapper {
  width: 100%;
  // overflow-y: auto;

  .InputBox {
    // min-width: 1400px;
  }
}
.groupButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 916px;
}

.lineParams {
  display: flex;
  align-items: center;
  & .InputBox {
    max-width: 450px;
    width: 100%;
    & .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.3;
      display: flex;
      align-items: center;
      color: #17172e;
      font-family: 'DM Sans', sans-serif;
      padding: 0;
      margin-bottom: 6px;
    }

    &:last-child {
      margin-left: 16px;
    }
  }
}

.inputNotEdit {
  height: 58px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;

  border: 2px solid #e8e8ef;
  width: 100%;
  position: relative;
  & .value {
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.16px;
    color: #17172e;
    width: 100%;
    padding: 17px;
    cursor: default;
  }
  & .iconEdit {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0%, -50%);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.button {
  button {
    width: 250px;
  }
  &__block {
    position: relative;
    display: flex;
    align-items: center;
  }
}
.discount {
  position: absolute;
  bottom: -25px;
  left: 0;
  color: #91cb3f;
  font-weight: 500;
  font-size: 16px;
}
.points {
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background: #ffffff;
  height: 60px;
  border-radius: 10px;
  padding: 15px 30px;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #17172e;

  span {
    font-weight: 700;
    padding-left: 5px;
  }
}
