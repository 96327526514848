.time {
  &_container {
    position: relative;
    height: 150px;
    width: 100%;
    z-index: 0;
    padding-top: 100px;
    padding-bottom: 130px;
    padding-left: 25px;
    padding-right: 35px;
    background: #fff;
    border: 2px solid #e8e8ef;
    box-sizing: border-box;
    border-radius: 8px;
    .react_time_range__handle_marker {
      display: none !important;
    }
  }
  &__block {
  }
}

.input_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #17172e;
  font-family: 'DM Sans', sans-serif;
  padding: 0;
  margin-bottom: 6px;
}

.error {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ec5d5d;
}
