.title {
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: 0.16px;
    color: #17172e;
    padding: 40px;
}
.boxForm {
    position: relative;
    z-index: 9;
    padding: 23px 40px;
}
.form {
    display: grid;
    gap: 24px;
}
