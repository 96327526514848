.inputNotEdit {
  height: max-content;
  min-height: 60px;
  padding-right: 20px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;

  border: 2px solid #e8e8ef;
  width: 100%;
  position: relative;
  z-index: 2;
  white-space: break-spaces;

  & .value {
    // font-size: 16px;
    // line-height: 1.3;
    // letter-spacing: 0.16px;
    // color: #17172e;
    // width: 100%;
    // padding: 17px;
    // cursor: pointer;
    font-size: 16px;
    line-height: 1.9;
    letter-spacing: 0.16px;
    color: #17172e;
    max-width: 95%;
    padding: 17px;
    cursor: pointer;
    // word-break: break-all;
  }

  & .iconEdit {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0%, -50%);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.notInteracts {
  pointer-events: none;
}

.disable {
  background: #E8E8EF;
  cursor: default;
}