.inner_side_cell {
    height: 57px;
    padding: 14.5px 40px;
    background: #2A2A4A;
    color: #A8A8CD;
    transition: .3s;
    text-align: left;

    &:not(.active):hover {
        cursor: pointer;
    }

    &.active {
        background: #404061;
        color: #FFFFFF;
    }
}