.btn {
  transition: all 0.2s;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #9e00ff;
  padding: 0px 20px;
  border-radius: 6px;

  &:hover {
    background: #f7f7f7;
  }
}
.text {
  color: #17172e;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}
