.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    &.active {
        background: #91CB3F;
    }
    &.blocked {
        background: #EC5D5D;
    }
}