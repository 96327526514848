.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  & .sign_in_window {
    & .header {
      & .welcome_message {
        color: #9a9aae;

        &.remove {
          display: none;
        }
      }

      margin-bottom: 55px;

      &.active {
        text-align: center;
      }
    }

    & .form {
      display: flex;
      flex-direction: column;

      align-items: center;

      & .inputs_group {
        display: grid;

        & .password_field {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 20px;
          &:nth-child(2) {
            margin-bottom: 57px;
          }

          & a {
            align-self: flex-end;
            text-decoration: underline;
            font-size: 14px;
            line-height: 24px;
            color: #7a7a96;
          }
        }
      }

      & .reset_password_button {
        align-self: center;
        width: 250px;
        height: 60px;
      }
    }
  }
}

.title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 0.16px;
  max-width: 410px;
  text-align: center;
  color: #17172e;
}

.description {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.16px;
  color: #7a7a96;
  margin-top: 20px;
  max-width: 410px;
  text-align: center;
}

.input_warning {
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  svg {
    padding-right: 12px;
  }
  span {
    display: block;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #7a7a96;
    max-width: 350px;
  }
}
