.container {
  padding: 0px 40px;
}
.boxForm {
  position: relative;
  z-index: 9;
  margin-bottom: 80px;

  @for $i from 1 through 99 {
    &:nth-child(#{$i}) {
      z-index: calc(99 - #{$i});
    }
  }
}
