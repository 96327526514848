#input_photo {
	position: absolute;
	z-index: 1;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.input {
	&_title {
		color: #17172e;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
	}

	&_image {
		display: none;
	}

	&_photo {
		&_label {
			position: relative;
			z-index: 2;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			max-width: 250px;
			width: 100%;
			height: 60px;
			border-radius: 10px;
			background: #9e00ff;
			color: #ffffff;
			transition: 0.3s;
			background: #9e00ff;
			color: #ffffff;

			&:hover {
				background: #ba48ff;
			}
		}

		&_text {
			font-weight: 500;
			font-size: 16px;
			line-height: 34px;
			text-align: center;
			color: #7a7a96;
			padding-bottom: 12px;
		}

		&_area {
			position: relative;
			background: #ffffff;
			border: 1px dashed #e8e8ef;
			box-sizing: border-box;
			padding: 30px 25px;
			margin-top: 8px;
			width: 300px;
			border-radius: 10px;

			input {
				cursor: pointer;
			}

			&.hidden {
				position: absolute;
				opacity: 0;
				z-index: -1;
			}

			&.image {
				padding: 60px 25px;

				.input_file {
					display: none;
				}

				.input_image {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					span {
						font-weight: normal;
						font-size: 14px;
						text-align: center;
						text-transform: uppercase;
						color: #17172e;
						padding-top: 15px;
					}
				}
			}

			&.error {
				border: 1px dashed #ec5d5d;
			}
		}
	}

	&__wrap {
		position: relative;
		margin-bottom: 20px;
	}
}

.file {
	&_settings {
		position: absolute;
		left: 310px;
		top: 35px;
	}

	&_delete,
	&_change {
		transition: all 0.2s;
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		span {
			transition: all 0.2s;
			padding-left: 10px;
		}

		path {
			transition: all 0.2s;
		}

		&:hover {
			span {
				color: #9e00ff;
			}

			path {
				fill: #9e00ff;
			}
		}
	}
}

.hint {
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	display: flex;
	align-items: center;

	/* Placeholder gray */

	color: #7d7d8a;

	&.error {
		color: #ec5d5d;
		position: absolute;
		bottom: -22px;
	}
}

.image {
	position: relative;
	z-index: 5;
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 10px;
}