.dropdown {
    position: relative;
    z-index: 9;
    max-width: 250px;
    width: 100%;
    // height: 100%;
}

.value_container {
    display: flex;
    align-items: center;

    & .label {
        margin-right: 10px;
    }
    .selected_value {
        display: flex;
        align-items: center;
    }
}