.input {
  &_multi {
    margin-bottom: 30px;
  }
}
.edit {
  display: flex;
}
.door {
  &__wrapper {
    max-height: 400px;

    .input_multi {
      position: relative;
      &:first-child {
        .delete {
          display: none;
        }
      }
    }
    .delete {
      position: absolute;
      z-index: 1;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: transparent;
      border-radius: 10px;
      color: #fff;
      font-weight: normal;
      font-size: 18px;
      line-height: 12px;

      svg {
        path {
          transition: all 0.2s;
          fill: #ec5d5d;
        }
      }
      &.edit {
        right: 23px;
        top: 20px;
        &:hover {
          svg {
            path {
              fill: rgb(192, 52, 52);
            }
          }
        }
      }
    }
    &.active {
      position: relative;
      z-index: 0;

      .delete {
        border: 2px solid #ec5d5d;
        right: -66px;
        top: 0;
        width: 60px;
        height: 59px;
        &:hover {
          background: #ec5d5d;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
      .input_multi {
        position: relative;
        width: calc(100% - 66px);
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
